import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import formatTitle from "../common/formatTitle";
import { Button } from "gatsby-material-ui-components";
import Grid from "@material-ui/core/Grid";
import ArrowIcon from "@material-ui/icons/ArrowForward";
import Typography from "@material-ui/core/Typography";
import IllustrationL from "./home_disrupt_left.png";
import IllustrationR from "./home_disrupt_right.png";
import ArrowButton from "../common/ArrowButton";

const useStyles = makeStyles((theme) => ({
  layoutMd: {
    padding: "5% 10%",
    textAlign: "center",
  },
  paddingTop: "7%",
  bgIllustr: {
    backgroundColor: theme.palette.background.medium,
    backgroundImage: `url(${IllustrationR}), url(${IllustrationL})`,
    backgroundRepeat: "no-repeat, no-repeat",
    backgroundPosition: "100% 0%, 0% 100%",
    backgroundSize: "auto 50%, auto 50%",
  },
  layoutSm: {
    padding: theme.layout.paddingRegularMobile,
  },
  blockCentered: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  paragLimit1: {
    maxWidth: "60%",
  },
  paragLimit2: {
    maxWidth: "85%",
    fontWeight: 600,
  },
  enLight: {
    color: theme.palette.primary.light,
  },
  txtBtnBlue: {
    color: theme.palette.primary.dark,
  },
  withBg: {
    backgroundColor: theme.palette.background.default,
  },
  pB: {
    paddingBottom: theme.spacing(4),
    backgroundColor: "transparent",
  },
  pT: {
    paddingTop: theme.spacing(4),
    backgroundColor: "transparent",
  },
  debug: {
    border: "solid 1px silver",
  },
}));

const DisruptiveSoftware = ({
  title1,
  title2,
  buttons,
  large,
  paddingTopPrct,
  alignContentCenter,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Grid
      item
      container
      direction="row"
      spacing={1}
      className={clsx(classes.withBg, {
        [classes.layoutMd]: matches,
        [classes.layoutSm]: !matches,
      })}
      justify="center"
    >
      <Grid
        item
        className={classes.bgIllustr}
        container
        direction="row"
        spacing={1}
        justify={alignContentCenter ? "center" : "flex-start"}
        style={{ padding: `${paddingTopPrct}% 0` }}
      >
        <Grid item className={clsx(classes.pT)} xs={12}>
          <Typography
            variant="h1"
            className={clsx(
              {
                [classes.paragLimit1]: matches && !large,
                [classes.paragLimit2]: matches && large,
              },
              classes.blockCentered
            )}
          >
            {formatTitle(title1)}
          </Typography>
        </Grid>
        <Grid item className={clsx(classes.pB)} xs={12} md={8}>
          <Typography
            variant="body1"
            color="textPrimary"
            className={clsx({ [classes.paragLimit]: matches })}
          >
            {title2}
          </Typography>
          {buttons.length > 0 && (
            <Grid
              item
              container
              direction={matches ? "row" : "column"}
              justify="center"
              alignItems={matches ? "stretch" : "center"}
              spacing={matches ? 8 : 2}
            >
              <Grid item>
                <ArrowButton
                  label={buttons[0].label}
                  color="primary"
                  to={buttons[0].uri}
                  noPaddingLeft={false}
                />
              </Grid>
              <Grid item>
                <ArrowButton
                  label={buttons[1].label}
                  to={buttons[1].uri}
                  color="primary"
                  noPaddingLeft={false}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

DisruptiveSoftware.defaultProps = {
  paddingTopPrct: 7,
  align: "center",
};

DisruptiveSoftware.defaultProps = {
  large: PropTypes.bool.isRequired,
  paddingTopPrct: PropTypes.number.isRequired,
  align: PropTypes.string.isRequired,
};

export default DisruptiveSoftware;
