import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    border: "solid 2px #CED6E9",
    borderRadius: 10,
    boxShadow: "6px 6px 10px rgb(67 67 68 / 11%)",
  },
  titre: {
    margin: 0,
    fontWeight: 600,
    fontSize: "1.1em",
  },
}));

const PatentMobile = ({ titre, date, no, timeSuccess, langKey }) => {
  const classes = useStyles();
  return (
    <Grid item container direction="column">
      <Grid item>
        <div className={classes.root}>
          <h5 className={classes.titre}>{titre}</h5>
          <div
            style={{ textAlign: "left", marginTop: 8, fontWeight: "normal" }}
          >
            {langKey === "fr" ? "Date de soumission" : "Date of submission"} :{" "}
            <span color="#000" style={{ fontWeight: 600 }}>
              {date}
            </span>
          </div>
          <div style={{ textAlign: "left", fontWeight: "normal" }}>
          No :{" "}
            <span color="#000" style={{ fontWeight: 600 }}>
              {no}
            </span>
          </div>
          <div style={{ textAlign: "left", fontWeight: "normal" }}>
          {langKey === "fr" ? "Statut" : "Status"} :{" "}
            <span color="#000" style={{ fontWeight: 600 }}>
              {timeSuccess
                ? langKey === "fr"
                  ? "validé"
                  : "filled"
                : langKey === "fr"
                ? "en cours"
                : "in progress"}
            </span>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default PatentMobile;
