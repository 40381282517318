import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  headerTitle: {
    color: "#FFF",
    fontWeight: "1000",
    position: "absolute",
    textAlign: "left",
    width: "100%",
    left: 160,
    bottom: "25%",    
  },
  headerTitleMobile: {
    left: 10
  },
  hTSm: {
    fontSize: "3em",
    color: "inherit",
    fontWeight: "normal",
  },
  hTMd: {
    fontSize: "5em",
    color: "inherit",
    fontWeight: 600,
  },
  tag: {
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    padding: theme.spacing(2),
    borderRadius: 50,
    fontWeight: 600,
  },
  mobile: {
    fontSize: "2em",
  }
}));

const Title = ({ text1, text2, matchesMobile }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <div className={clsx(classes.headerTitle, {[classes.headerTitleMobile]: matchesMobile })} id="headerTitle">
      <div
        style={{
          marginBottom: theme.spacing(4),
        }}
      >
        <span className={classes.tag}>{"Scientific R&D"}</span>
      </div>
      <Typography
        variant="h1"
        className={clsx({
          [classes.hTMd]: matches,
          [classes.hTSm]: !matches,
          [classes.mobile]: matchesMobile
        })}
      >
        {text1}
        <br />
        {text2}
      </Typography>
    </div>
  );
};

Title.propTypes = {
  text1: PropTypes.string.isRequired,
  text2: PropTypes.string.isRequired,
};

export default Title;
