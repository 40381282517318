import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Button } from "gatsby-material-ui-components";
import { makeStyles } from "@material-ui/core/styles";
import formatTitle from "../formatTitle";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const colors = {
  deep_learning_optimization: "#18D1A7",
  traffic_management: "#E20026",
  incident_detection_in_tunnel: "#1F3598",
  tolling_classification: "#FFC700",
  platematching: "#FF8358",
};

const useStyles = makeStyles((theme) => ({
  padWrapper: {
    "& :hover": {
      boxShadow: "0px 30px 50px rgba(67,67,68, 0.109804);",
    },
  },
  pad: {
    border: "solid 1px #E7EBF4",
    borderRadius: 10,
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    "& :hover": {
      boxShadow: "none",
    },
  },
  action: {
    textAlign: "center",
    "& :hover": {
      boxShadow: "none",
    },
  },
  object: {
    color: theme.palette.primary.dark,
    fontSize: "1.2em",
    fontWeight: 600,
    minHeight: 90,
  },
  date: {
    color: "grey",
    marginTop: theme.spacing(1),
    fontSize: "0.8em",
  },
  authors: {
    color: "#000",
    fontSize: "1.1em",
    fontWeight: 600,
    minHeight: 100,
    "& :hover": {
      boxShadow: "none",
    },
    borderBottom: "solid 1px #E7EBF4",
  },
  content: {
    fontSize: "1.1em",
    lineHeight: "1.8em",
    minHeight: 220,
    maxHeight: 220,
    "& :hover": {
      boxShadow: "none",
    },
    overflowY: "auto",
    WebkitAppearance: "none",
  },
}));

const PeerReviewdPaper = ({
  object,
  objectTr,
  date,
  authors,
  content,
  acceptation,
  url,
  langKey,
  matchesMobile,
}) => {
  const classes = useStyles();
  const colorKey = object.toLowerCase().split(" ").join("_");
  return (
    <Grid item xs={12} sm={4} className={classes.padWrapper}>
      <div
        className={clsx(classes.pad)}
        style={{ borderTop: `solid 15px ${colors[colorKey]}` }}
      >
        <Grid container direction="column" justify="flex-start" spacing={0}>
          <Grid item className={classes.object}>
            <span style={{ color: colors[colorKey] }}>{objectTr}</span>
            <br />
            <span className={classes.date}>{date}</span>
          </Grid>
          <Grid item className={classes.authors}>
            {authors}
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              color="textPrimary"
              className={classes.content}
            >
              <em>{content}</em>
              <br />
              {formatTitle(acceptation)}
            </Typography>
          </Grid>
          <Grid item className={classes.action}>
            <Button
              href={url}
              target="__blank"
              variant="contained"
              style={{
                backgroundColor: colors[colorKey],
                color: "#FFF",
              }}
              size="large"
            >
              {langKey === "fr" ? "Voir l'article" : "See the paper"}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

PeerReviewdPaper.propTypes = {
  object: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  authors: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  acceptation: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  matchesMobile: PropTypes.bool,
};

export default PeerReviewdPaper;
